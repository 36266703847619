body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.active {
  background-color: #4CAF50;
  color: white;
}
li {
  display: inline;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes animateCloud  {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 1280px 0;
  }
}

@keyframes beat{
	to { 
    transform: scale(1.2); 
  }
}