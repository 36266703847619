* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  // height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

body {
  background-color: #f4f6f8;
  height: 100%;
}
a {
  text-decoration: none;
}


#root {
  height: 100%;
}

.errorbounday {
  color: #31708f;
  font-size: 16px;
  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.05px;
}